@font-face {
  font-family: "ESK";
  src: url("/public/ESKlarheitGroteskMono-Rg.woff") format("woff");
  src: url("/public/ESKlarheitGroteskMono-Rg.woff2") format("woff2");
}

body {
  margin: 0;
  font-size: 12px;
  box-sizing: border-box;
  cursor: url("/public/images/cursor.png"), auto;
  font-family: "ESK", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 1898px) {
    font-size: 14px;
  }
}

a, button {
  cursor: url("/public/images/cursor2.png"), auto !important;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

